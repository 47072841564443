<template>
    <div class="main">
        <div class="main-sessions">
            <p>小小运动会赛程</p>
            <div v-if="sessionsList.length > 1">
                <p v-for="item in sessionsList" :key="item.id" :class="{active : params.contestSessionsId == item.id}" @click="changeSessions(item)">第{{num[item.contestSessions]}}场</p>
            </div>
        </div>
        <div class="content">
            <div class="bage_whie" v-if="showDraw">
                <div class="margin_30 disply_center" v-if="drawstatus">
                    <div class="titleluckaw">{{$store.state.schoolInfo.atteSchool.schoolName}}</div>
                    <div class="choujiang" @click="clickshowbg">一键抽签</div>
                </div>
                <div class="margin_30 disply_center" v-else>
                    <div class="titleluckaw">{{$store.state.schoolInfo.atteSchool.schoolName}}</div>
                    <div class="number">{{numberinddex}}</div>
                    <div class="chouqiantext">抽签号码</div>
                </div>
                <div class="fgx"></div>
            </div>

            <div class="absolutebox" v-if="showbg">
                <div class="niu_danji">
                    <!--机器-->
                    <div class="game_qu">
                        <div class="game_go" @click="game_go"></div>

                    </div>

                    <!--球-->
                    <div class="dan_gund">
                        <span class="qiu_1 diaol_1"></span>
                        <span class="qiu_2 diaol_2"> </span>
                        <span class="qiu_3 diaol_3"></span>
                        <span class="qiu_4 diaol_4"></span>
                        <span class="qiu_5 diaol_5"></span>
                        <span class="qiu_6 diaol_6"></span>
                        <span class="qiu_7 diaol_7"></span>
                        <span class="qiu_8 diaol_8"></span>
                        <span class="qiu_9 diaol_9"></span>
                        <span class="qiu_10 diaol_10"></span>
                        <span class="qiu_11 diaol_11"></span>
                    </div>

                    <!--中奖掉落-->
                    <div class="medon"><img src="../../assets/img/sports/mendong.png"></div>
                    <div class="zjdl">
                        <span></span>
                    </div>
                </div>
                <em class="niu_danji_close" @click="closeCj"></em>
            </div>


            <!--中奖 获得一等奖-->
            <div class="zonj_zezc none" id="jianpin_one">
                <div class="jpzs aiqiyi tc_anima">
                    <em class="close" @click="close"></em>
                    <h2>
                        <b>恭喜你！<br>抽得<text id="number"></text>号</b>
                    </h2>
                </div>
            </div>
        </div>
        <h1 class="tit">{{info.contestNewsName}}</h1>
        <!-- <div class="sub-tit">
            <span>来源：全国足球特色幼儿园系统平台</span>
            <span>{{info.createTime}}</span>
        </div> -->
        
        <div class="cont">
            <!-- <img :src="constant.URL + info.coverImage" v-if="info.coverImage" alt=""> -->
            <p v-html="info.context"></p>
        </div>
    </div>
</template>
<script>
 
export default {
    name:'news',
    data(){
        return{
            sessionsList:[],
            info:{},
            params:{
                "contestAreaId": '', //赛区id
                "contestNewType": 4,    //频道栏目类型 1 赛事 2 花边 3 轮播 4 赛程
                "contestSessions": '',   //场次数
                "contestSessionsId": ''  //场次id
            },
            num:['零', '一', '二', '三', '四', '五', '六', '七', '八', '九','十','十一','十二'],
            score: 1000,
            flag: true, // 节流阀 控制不能连续点击
            showbg: false,
            numberinddex: 0,
            // showDraw:false,
            // showDraw:true,
            drawstatus: true,
            clock_bg: '/static/images/haibao_one.png',
            name: '北京幼儿园',
            canvsstatus: false,
            imstustu: true,
            imgUrl: '',
            canvasW: '',
            canvasH: '',
            // positondata:{},
            newlist:'',
            isSignUp:false, //是否报名
            contestTeamId:'',   //组id 抽奖用
            isSuccess:null, //是否抽奖成功 解决抽奖失败时弹出抽奖号码问题
        }
    },
    computed:{
        showDraw:function(){
            return this.$store.state.userInfo.token && this.isSignUp && this.contestTeamId;
        }
    },
    components:{},
    watch:{
        "$store.state.spotrsindex"(newval){
            if(newval){
                this.params.contestAreaId = this.$store.state.spotrsindex;
                this.init();
            }
        },
        contestTeamId(newval){
            if(newval){
                //判断是否抽过奖  0 查询  1抽奖
                this.recordgetbtn(0);
            }
        },
        "$store.state.userInfo.token"(newval){
            if(newval){
                //获取是否报名
                this.contestSchoolGet();
            }
        }
    },
    mounted(){
        this.params.contestAreaId = this.$store.state.spotrsindex;
        if(this.params.contestAreaId){
            this.init();
        }
    },
    methods:{
        init(){
            //获取场次列表
            this.getSessionsList();
            if(this.$store.state.userInfo.token){
                //获取是否报名
                this.contestSchoolGet();
            }
        },
        closeCj(){
            this.showbg = false;
        },
        getSessionsList(){
            this.api.sports.sessionsList(this.params.contestAreaId).then((res)=>{
                if(res.flag){
                    this.sessionsList = res.data;
                    this.params.contestSessions = res.data[0].contestSessions;
                    this.params.contestSessionsId = res.data[0].id;
                    //请求成功后调用
                    this.getScheduleInfo();
                    this.sessionsGetSchedule();
                }else{
                    this.$notice({
                      message: res.message,
                    }).isShow();
                }
            });
        },
        getScheduleInfo(){  //获取赛程新闻详情
            this.api.sports.scheduleNews(this.params).then((res)=>{
                if(res.flag){
                    this.info = res.data;
                }else{
                    this.$notice({
                      message: res.message,
                    }).isShow();
                }
            })
        },
        changeSessions(data){
            this.contestTeamId = '';
            this.drawstatus = true;
            this.numberinddex = 0;
            this.isSuccess = null;
            this.params.contestSessions = data.contestSessions;
            this.params.contestSessionsId = data.id;
            this.getScheduleInfo();
            this.sessionsGetSchedule();
        },
        recordgetbtn(operation){
            var that = this
            var data ={
                contestSessionsId: this.params.contestSessionsId,
                contestTeamId: this.contestTeamId,
                operation:operation
            }
            this.api.sports.recordget(data).then((res)=>{
                console.log(res,'ddddd')
                if(res.code == 20000){
                    this.isSuccess = true; //判断是否抽奖成功
                    that.numberinddex = res.data.drawNum
                    if(!res.data.draw){    //是否抽过奖  0 否 1 是
                        that.drawstatus = true
                    }else{
                        that.drawstatus = false
                    }
                }else{
                    this.isSuccess = false; //判断是否抽奖成功
                    this.$notice({
                      message: res.message,
                    }).isShow();
                }
            })
        },
        clickshowbg() {
            if(!this.$store.state.userInfo.teacherId){
                this.showbg = true;
            }else{
                this.$notice({
                    message: '请用园长账号登录抽签',
                }).isShow();
            }
        },
        game_go() {
            var that = this
            if (that.flag) {
                that.flag = false;
                that.score -= 10;
                if (that.score < 0) {
                    for (let i = 1; i <= 11; i++) {
                        this.$(".qiu_" + i).removeClass("wieyi_" + i);
                    }
                    this.$("#no_jifeng").show();
                } else {
                    that.draw();
                }
            }
        },

        random(min, max) {
            return Math.floor(Math.random() * (max - min)) + min;
        },
        draw() {
            // 随机中奖号码 number标识
            // var number
            var that = this

            for (var i = 1; i <= 11; i++) {
                that.$(".qiu_" + i).addClass("wieyi_" + i);
            }
            setTimeout(function() {
                for (let i = 1; i <= 11; i++) {
                    that.$(".qiu_" + i).removeClass("wieyi_" + i);
                }
            }, 1100);

            setTimeout(function() {
                that.$(".zjdl").children("span").addClass("diaL_one");
                setTimeout(function() {
                    console.log(this.isSuccess,'this.isSuccess')
                    // if(this.isSuccess){
                        that.$("#jianpin_one").show();
                        var mumber = that.numberinddex
                        // console.log(mumber)
                        that.$("#number").html(mumber)
                    // }
                }, 900);
            }, 3000)

            //取消动画
            setTimeout(function() {
                that.$(".zjdl").addClass("none").removeClass("dila_Y");
                // $(".wdjifen").html(that.score);
                that.$(".zjdl").children("span").removeAttr('class');
                that.flag = true;
            }, 3500)
            this.recordgetbtn(1)
        },
        close() {
            var that = this
            if (that.numberinddex) {
                that.drawstatus = false
            }
            that.showbg = false
            that.$('.zonj_zezc').fadeOut();
        },
        closebg(){
            this.canvsstatus = false
        },
        contestSchoolGet(){ //判断是否报名
            // if(!!this.params.contestAreaId) return;
            let data = {
                areaId:Number(this.params.contestAreaId),
            };
            if(this.$store.state.schoolInfo.atteSchool && this.$store.state.schoolInfo.atteSchool.id){
                data.schoolId = this.$store.state.schoolInfo.atteSchool.id;
            }
            this.api.sports.contestSchoolGet(data).then((res)=>{
                if(res.code == 20000){
                    this.isSignUp = res.data.isEnroll;
                }else{
                    this.$notice({
                      message: res.message + '5555',
                    }).isShow();
                }
            })
        },
        sessionsGetSchedule(){ //获取场次的具体赛程 GET

            let data = {
                sessionId:this.params.contestSessionsId,
            };
            if(this.$store.state.schoolInfo.atteSchool && this.$store.state.schoolInfo.atteSchool.id){
                data.schoolId = this.$store.state.schoolInfo.atteSchool.id;
            }
            this.api.sports.sessionsGetSchedule(data).then((res)=>{
                if(res.code == 20000){
                    this.contestTeamId = res.data.contestTeam.id;
                }else{
                    this.$notice({
                      message: res.message + '6666',
                    }).isShow();
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
@import './css/schedule.scss';
.main{
    margin:0 auto;
    padding:0 40px 40px 40px;
    color: #333333;
    width:1354px;
    background:#FFFFFF;
    .main-sessions{
        padding-top:30px;
        font-size: 18px;
        font-weight: 400;
        color: #AAAAAA;
        @include flex(row,flex-start,center);
        >p{
            margin-right:40px;
        }
        >p:nth-child(1){
            margin-right:30px;
            font-size: 32px;
            font-weight: bold;
            color: #BB3037;
        }
        >p:not(:first-child){
            cursor:pointer;
        }
        >p.active{
            color: #BB3037;
        }
    }
    .tit{
        margin-top:40px;
        margin-bottom: 10px;
        font-size: 30px;
        font-weight: bold;
        text-align: center;
    }
    .sub-tit{
        font-size: 16px;
        text-align: center;
        >span:nth-child(1){
            padding-right:40px;
        }
    }
    .cont{
        margin:25px auto 0 auto;
        width:900px;
        font-size: 18px;
        color: #333333;
        line-height: 30px;
        img{
            width:100% !important;
            // height: 420px;
        }
        > p{
            margin-top: 30px;
            line-height: 30px;
            p{
                span{
                    font-family: inherit !important;;
                    font-size:18px !important;
                }
            }
            
        }
    }
}
</style>